@import "bootstrap/scss/functions"; 
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

$h7-font-size: $font-size-base * 0.8;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: $h7-font-size
);

$utilities: map-merge(
  $utilities,
  (
    "font-size": map-merge(
      map-get($utilities, "font-size"),
      (
        values: $font-sizes,
      ),
    ),
  )
);

.display-table {
  display: table;
}

@import "~bootstrap/scss/bootstrap";
